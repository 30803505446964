import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { getShipmentColor } from '@utils/get-shipment-colors';
import type { NotificationItemContentContainerFragment } from 'generated/graphql';

type StyledNotificationProps = {
  shipmentColor: string;
};

const StyledNotificationContentItem = styled.span<StyledNotificationProps>`
  color: ${(p) => p.shipmentColor};
  font-weight: 600;
`;

const StyledNotificationContentLink = styled.a<StyledNotificationProps>`
  color: ${(p) => p.shipmentColor};
  font-weight: 600;
`;

type Props = {
  href: string | null;
  shipment: NotificationItemContentContainerFragment | null;
  children: React.ReactNode;
};

const NotificationItemContentContainer = ({
  href,
  shipment,
  children,
}: Props): JSX.Element => {
  const theme = useTheme();

  const shipmentColor = getShipmentColor(shipment, theme);

  return (
    <>
      {href ? (
        <StyledNotificationContentLink
          href={href}
          shipmentColor={shipmentColor}
        >
          {children}
        </StyledNotificationContentLink>
      ) : (
        <StyledNotificationContentItem shipmentColor={shipmentColor}>
          {children}
        </StyledNotificationContentItem>
      )}
    </>
  );
};

export { NotificationItemContentContainer };
