import { gql, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import Popover from '@mui/material/Popover';
import type {
  DashboardReportIssueMutation,
  DashboardReportIssueMutationVariables,
} from 'generated/graphql';
import type { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { DashboardReportIssueForm } from './form';
import type { DashboardReportIssueFormValues } from './form/dashboard-report-issue-form';

const DASHBOARD_REPORT_ISSUE_MUTATION = gql`
  mutation DashboardReportIssueMutation($input: CreateBugReportInput!) {
    dashboard {
      createBugReport(input: $input) {
        id
      }
    }
  }
`;

const StyledDashboardReportFab = styled(Fab)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.internationalOrange700};
  transition: opacity 0.2s ease-in-out;

  :hover,
  :focus-visible {
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.internationalOrange700};
    opacity: 0.7;
  }
`;

const StyledReportIssueText = styled.p`
  font-size: 0.75rem;
  text-transform: capitalize;
`;

const StyledReportIssueFormContainer = styled.div`
  min-width: 400px;
  padding: 24px;
`;

type IssueIdentifier =
  | 'shipmentId'
  | 'palletEventId'
  | 'loadingEventId'
  | 'exceptionId'
  | 'orderId';

function getIssueIdentifiers(query: NextParsedUrlQuery): {
  [key in IssueIdentifier]?: string;
} {
  const identifiers: { [key in IssueIdentifier]?: string } = {};

  if (query.shipmentId) {
    identifiers.shipmentId = String(query.shipmentId);
  }
  if (query.activity && query.type === 'loading') {
    identifiers.loadingEventId = String(query.activity);
  }
  if (query.orderId) {
    identifiers.orderId = String(query.orderId);
  }
  if (query.exceptionId) {
    identifiers.exceptionId = String(query.exceptionId);
  }
  if (query.pallet) {
    identifiers.palletEventId = String(query.pallet);
  }
  return identifiers;
}

const DashboardReportIssue = (): JSX.Element => {
  const [
    reportIssue,
    { loading: reportIssueMutationLoading, error: reportIssueMutationError },
  ] = useMutation<
    DashboardReportIssueMutation,
    DashboardReportIssueMutationVariables
  >(DASHBOARD_REPORT_ISSUE_MUTATION);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { query } = useRouter();

  const handleReportIssue = useCallback(
    async ({
      description,
      primaryCategory,
      secondaryCategory,
      screenshotUpload,
      priority,
    }: DashboardReportIssueFormValues) => {
      try {
        await reportIssue({
          variables: {
            input: {
              description,
              url: window.location.href,
              primaryCategory,
              secondaryCategory,
              priority,
              file: screenshotUpload,
              ...getIssueIdentifiers(query),
            },
          },
        });

        setAnchorEl(null);
      } catch (err) {
        console.error(err);
      }
    },
    [reportIssue, query],
  );

  return (
    <>
      <StyledDashboardReportFab
        variant='extended'
        size='small'
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <ErrorOutline fontSize='small' />

        <StyledReportIssueText>Report an issue</StyledReportIssueText>
      </StyledDashboardReportFab>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledReportIssueFormContainer>
          <DashboardReportIssueForm
            isActionSubmitting={reportIssueMutationLoading}
            onFormSubmit={(formValues) => {
              handleReportIssue(formValues);
            }}
            onFormClose={() => {
              setAnchorEl(null);
            }}
          />

          {reportIssueMutationError && (
            <Typography color='error' fontSize='0.75rem'>
              Error reporting issue. Please try again
            </Typography>
          )}
        </StyledReportIssueFormContainer>
      </Popover>
    </>
  );
};

export { DashboardReportIssue };
