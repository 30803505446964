import { KargoActionLoadingButton } from '@components/kargo-ui/action-loading-button';
import { KargoSelect } from '@components/kargo-ui/select';
import { KargoOption } from '@components/kargo-ui/select/option';
import { KargoSimpleUpload } from '@components/kargo-ui/simple-file-upload';
import { FILE_TYPE } from '@components/kargo-ui/simple-file-upload/simple-file-upload';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import type { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { useConfig } from 'statsig-react';

const StyledReportIssueForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledReportIssueEntitySelect = styled(KargoSelect)`
  flex-grow: 1;
`;

const StyledReportIssueNoEntitySelectedLabel = styled.label`
  color: ${(p) => p.theme.colors.gray500};
`;

const StyledReportIssueTextField = styled(TextField)`
  & .dashboard-report-issue-form__entity-text-field-input {
    font-size: 0.875rem;
  }

  & .dashboard-report-issue-form__entity-text-field-input-label {
    font-size: 0.875rem;
    color: ${(p) => p.theme.colors.gray500};
  }
`;

const StyledReportIssueScreenshotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const StyledReportIssueScreenshotFileName = styled.p`
  font-size: 0.75rem;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledReportIssueActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

const StyledReportIssueCancelButton = styled(Button)`
  color: ${(p) => p.theme.colors.gray500};
  border-radius: 24px;
  padding: 6px 20px;
  text-transform: none;

  :hover,
  :focus-visible {
    background-color: ${(p) => p.theme.colors.gray200};
  }
`;

export type DashboardReportIssueFormValues = {
  description: string;
  primaryCategory: string;
  secondaryCategory: string;
  screenshotUpload: File | null;
  priority: string;
};

type Props = {
  isActionSubmitting: boolean;
  onFormSubmit: (formValues: DashboardReportIssueFormValues) => void;
  onFormClose: () => void;
};

const DashboardReportIssueForm = ({
  isActionSubmitting,
  onFormSubmit,
  onFormClose,
}: Props): JSX.Element => {
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<DashboardReportIssueFormValues>({
    defaultValues: {
      description: '',
      primaryCategory: '',
      secondaryCategory: '',
      screenshotUpload: null,
      priority: 'Medium',
    },
  });

  const { config: dashboardReportIssueConfig, isLoading } = useConfig(
    'dashboard_report_issue_config',
  );

  const watchedPrimaryCategoryField = watch('primaryCategory');
  const watchedScreenshotUpload = watch('screenshotUpload');

  const availableCategories = dashboardReportIssueConfig.get(
    watchedPrimaryCategoryField,
    [],
  ) as string[];

  return (
    <StyledReportIssueForm onSubmit={handleSubmit(onFormSubmit)}>
      <Controller
        name='primaryCategory'
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <StyledReportIssueEntitySelect
            value={field.value}
            displayEmpty
            renderValue={(value) =>
              (value as string) || (
                <StyledReportIssueNoEntitySelectedLabel>
                  Category
                </StyledReportIssueNoEntitySelectedLabel>
              )
            }
            onChange={(e: SelectChangeEvent<unknown>) => {
              setValue('primaryCategory', e.target.value as string);
              setValue('secondaryCategory', '');
            }}
          >
            {!isLoading &&
              Object.keys(dashboardReportIssueConfig.value).map(
                (issueKey: string) => (
                  <KargoOption key={issueKey} value={issueKey}>
                    {issueKey}
                  </KargoOption>
                ),
              )}
          </StyledReportIssueEntitySelect>
        )}
      />

      <Controller
        name='secondaryCategory'
        control={control}
        render={({ field }) => (
          <StyledReportIssueEntitySelect
            value={field.value}
            displayEmpty
            disabled={!watchedPrimaryCategoryField}
            renderValue={(value) =>
              (value as string) || (
                <StyledReportIssueNoEntitySelectedLabel>
                  Problem
                </StyledReportIssueNoEntitySelectedLabel>
              )
            }
            onChange={(e: SelectChangeEvent<unknown>) => {
              setValue('secondaryCategory', e.target.value as string);
            }}
          >
            {watchedPrimaryCategoryField &&
              availableCategories.map((category: string) => (
                <KargoOption key={category} value={category}>
                  {category}
                </KargoOption>
              ))}
          </StyledReportIssueEntitySelect>
        )}
      />

      <Controller
        name='priority'
        control={control}
        render={({ field }) => (
          <StyledReportIssueEntitySelect
            value={field.value}
            displayEmpty
            renderValue={(value) =>
              (value as string) || (
                <StyledReportIssueNoEntitySelectedLabel>
                  Priority
                </StyledReportIssueNoEntitySelectedLabel>
              )
            }
            onChange={(e: SelectChangeEvent<unknown>) => {
              setValue('priority', e.target.value as string);
            }}
          >
            {['Lowest', 'Low', 'Medium', 'High', 'Highest'].map(
              (priority: string) => (
                <KargoOption key={priority} value={priority}>
                  {priority}
                </KargoOption>
              ),
            )}
          </StyledReportIssueEntitySelect>
        )}
      />

      <Controller
        name='description'
        control={control}
        render={({ field }) => (
          <>
            <StyledReportIssueTextField
              size='small'
              label='Please describe your issue'
              multiline
              rows={4}
              InputLabelProps={{
                classes: {
                  root: 'dashboard-report-issue-form__entity-text-field-input-label',
                },
              }}
              InputProps={{
                classes: {
                  root: 'dashboard-report-issue-form__entity-text-field-input',
                },
              }}
              {...field}
            />
          </>
        )}
      />

      <StyledReportIssueScreenshotContainer>
        <Controller
          name='screenshotUpload'
          control={control}
          render={() => (
            <KargoSimpleUpload
              buttonText='Upload screenshot'
              handleUpload={(file) => {
                setValue('screenshotUpload', file);
              }}
              isUploading={isActionSubmitting}
              uploadError={!!errors.screenshotUpload?.message}
              allowedFileLimit={1}
              allowedFileTypes={[FILE_TYPE.JPG, FILE_TYPE.PNG]}
              fileLimitErrorMessage='Only 1 screenshot can be uploaded'
              fileTypeErrorMessage='Only image files are allowed'
            />
          )}
        />

        {watchedScreenshotUpload && (
          <StyledReportIssueScreenshotFileName>
            {watchedScreenshotUpload.name}
          </StyledReportIssueScreenshotFileName>
        )}
      </StyledReportIssueScreenshotContainer>

      <StyledReportIssueActionContainer>
        {errors.primaryCategory?.type === 'required' && (
          <Typography color='error' fontSize='0.75rem'>
            A category is required
          </Typography>
        )}

        <StyledReportIssueCancelButton
          onClick={() => {
            onFormClose();
          }}
        >
          Cancel
        </StyledReportIssueCancelButton>

        <KargoActionLoadingButton
          type='submit'
          disabled={!watchedPrimaryCategoryField}
          loading={isActionSubmitting}
        >
          Submit
        </KargoActionLoadingButton>
      </StyledReportIssueActionContainer>
    </StyledReportIssueForm>
  );
};

export { DashboardReportIssueForm };
