import { NotificationItemExceptionOwner } from '@components/shared/notifications/items/exception-owner';
import { NotificationItemExceptionShared } from '@components/shared/notifications/items/exception-shared';
import { NotificationItemNoteReply } from '@components/shared/notifications/items/note-reply';
import { NotificationItemNoteTag } from '@components/shared/notifications/items/note-tag';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { DashboardNotificationsListFragment } from 'generated/graphql';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';

type StyledDashboardNotificationsListItemProps = {
  isUnread: boolean;
};

const StyledDashboardNotificationsListItem = styled.li<StyledDashboardNotificationsListItemProps>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  transition: background-color 0.1s ease-in-out;

  :hover {
    background-color: ${(p) => p.theme.colors.gray300};
  }

  ${(p) =>
    p.isUnread &&
    css`
      background-color: ${p.theme.colors.gray100};
    `}
`;

const StyledDashboardNotificationsTimestamp = styled.p`
  color: ${(p) => p.theme.colors.gray400};
  font-size: 0.75rem;
`;

type Props = {
  notifications: DashboardNotificationsListFragment[];
};

const DashboardNotificationsList = ({ notifications }: Props): JSX.Element => {
  const [, forceUpdate] = useState<{}>({});

  // Re-render component every minute to update timestamps
  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate({});
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ul>
      {notifications.map((notification) => {
        const { id, occurredAt, read } = notification;

        const formattedTimestamp = moment(new Date(occurredAt)).fromNow();
        const timestampNode = (
          <StyledDashboardNotificationsTimestamp>
            {formattedTimestamp}
          </StyledDashboardNotificationsTimestamp>
        );

        let notificationContent = null;

        switch (notification.__typename) {
          case 'ExceptionOwnerNotification':
            notificationContent = (
              <NotificationItemExceptionOwner
                notification={notification}
                timestamp={timestampNode}
                isFullyClickable
                showFacilityLabel
              />
            );

            break;
          case 'ExceptionSharedNotification':
            notificationContent = (
              <NotificationItemExceptionShared
                notification={notification}
                timestamp={timestampNode}
                isFullyClickable
                showFacilityLabel
              />
            );

            break;
          case 'NoteTagNotification':
            notificationContent = (
              <NotificationItemNoteTag
                notification={notification}
                timestamp={timestampNode}
                isFullyClickable
                showFacilityLabel
              />
            );

            break;
          case 'NoteReplyNotification':
            notificationContent = (
              <NotificationItemNoteReply
                notification={notification}
                timestamp={timestampNode}
                isFullyClickable
                showFacilityLabel
              />
            );

            break;
        }

        return (
          <Fragment key={id}>
            {notificationContent && (
              <StyledDashboardNotificationsListItem isUnread={!read}>
                {notificationContent}
              </StyledDashboardNotificationsListItem>
            )}
          </Fragment>
        );
      })}
    </ul>
  );
};

export { DashboardNotificationsList };
