import Stack from '@mui/material/Stack';
import pluralize from 'pluralize';
import { useCallback, useEffect, useRef } from 'react';
import type { ErrorOption } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { KargoActionLoadingButton } from '../action-loading-button';

export enum BUTTON_ALIGNMENT {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum FILE_TYPE {
  CSV = 'text/csv',
  PDF = 'application/pdf',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLSM = 'application/vnd.ms-excel.sheet.macroenabled.12',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  EML = 'message/rfc822',
}

const DEFAULT_ERROR_MESSAGES: Record<string, ErrorOption> = {
  FILE_LIMIT: {
    type: 'file_limit',
    message: 'File limit exceeded.',
  },
  FILE_SIZE: {
    type: 'file_size',
    message: 'Maximum file size exceeded.',
  },
  FILE_TYPE: {
    type: 'file_type',
    message: 'File type/extension not allowed.',
  },
  UPLOAD_EXCEPTION: {
    type: 'upload_exception',
    message: 'Error uploading file. Please try again.',
  },
};

const FILE_FIELD_NAME = 'filesToUpload';

type FileUploadForm = {
  [FILE_FIELD_NAME]: FileList;
};

type KargoSimpleUploadProps = {
  handleUpload: (file: File) => void;
  isUploading: boolean;
  buttonText?: string;
  buttonAlignment?: BUTTON_ALIGNMENT;
  uploadError?: boolean;
  allowedFileLimit?: number;
  allowedFileSize?: number;
  allowedFileTypes?: FILE_TYPE[];
  uploadErrorMessage?: string;
  fileLimitErrorMessage?: string;
  fileSizeErrorMessage?: string;
  fileTypeErrorMessage?: string;
};

const KargoSimpleUpload = ({
  handleUpload,
  isUploading,
  buttonText = 'Upload file',
  buttonAlignment = BUTTON_ALIGNMENT.LEFT,
  uploadError = false,
  allowedFileLimit = 5,
  allowedFileSize = 20,
  allowedFileTypes = Object.values(FILE_TYPE),
  uploadErrorMessage = DEFAULT_ERROR_MESSAGES.UPLOAD_EXCEPTION.message,
  fileLimitErrorMessage = DEFAULT_ERROR_MESSAGES.FILE_LIMIT.message,
  fileSizeErrorMessage = DEFAULT_ERROR_MESSAGES.FILE_SIZE.message,
  fileTypeErrorMessage = DEFAULT_ERROR_MESSAGES.FILE_TYPE.message,
}: KargoSimpleUploadProps) => {
  const fileUploadRef = useRef<HTMLInputElement | null>();
  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useForm<FileUploadForm>();
  const { [FILE_FIELD_NAME]: uploadFiles } = watch();
  const { ref: fileFieldFormInputRef, ...fileFieldFormInputProps } =
    register(FILE_FIELD_NAME);

  const validateForm = useCallback(
    (data: FileUploadForm) => {
      const files: File[] = Array.from(data[FILE_FIELD_NAME]);
      let isFormValid = true;

      if (files.length) {
        const isFileLimitExceeded = files.length > allowedFileLimit;

        if (isFileLimitExceeded) {
          const fileLimitError: ErrorOption = {
            type: DEFAULT_ERROR_MESSAGES.FILE_LIMIT.type,
            message: fileLimitErrorMessage,
          };

          setError(FILE_FIELD_NAME, fileLimitError);
          isFormValid = false;
        }

        files.forEach((file) => {
          // we want allowedFileSize prop to accept the file in MB, so we have to do multiply
          // the file size bytes by 1000000
          const isFileSizeExceeded = file.size > allowedFileSize * 1000000;
          const isForbiddenFiletype =
            file.type === '' ||
            !allowedFileTypes.includes(file.type as FILE_TYPE);

          if (isFileSizeExceeded) {
            const fileSizeError: ErrorOption = {
              type: DEFAULT_ERROR_MESSAGES.FILE_SIZE.type,
              message: fileSizeErrorMessage,
            };

            setError(FILE_FIELD_NAME, fileSizeError);
            isFormValid = false;
          } else if (isForbiddenFiletype) {
            const fileTypeError: ErrorOption = {
              type: DEFAULT_ERROR_MESSAGES.FILE_TYPE.type,
              message: fileTypeErrorMessage,
            };

            setError(FILE_FIELD_NAME, fileTypeError);
            isFormValid = false;
          }
        });
      }
      return isFormValid;
    },
    [
      setError,
      allowedFileLimit,
      allowedFileSize,
      allowedFileTypes,
      fileLimitErrorMessage,
      fileSizeErrorMessage,
      fileTypeErrorMessage,
    ],
  );

  const onSubmit = useCallback(
    (data: FileUploadForm) => {
      if (validateForm(data)) {
        const files: File[] = Array.from(data[FILE_FIELD_NAME]);

        files.forEach((file) => {
          handleUpload(file);
        });

        reset();
      }
    },
    [handleUpload, reset, validateForm],
  );

  useEffect(() => {
    // submit form on select files
    if (uploadFiles && uploadFiles.length) {
      handleSubmit(onSubmit)();
    }
  }, [uploadFiles, handleSubmit, onSubmit]);

  return (
    <Stack
      alignItems='center'
      gap={2}
      direction={
        buttonAlignment === BUTTON_ALIGNMENT.LEFT ? 'row' : 'row-reverse'
      }
    >
      <input
        {...fileFieldFormInputProps}
        ref={(e) => {
          fileFieldFormInputRef(e);
          fileUploadRef.current = e;
        }}
        id={FILE_FIELD_NAME}
        data-testid='upload-input'
        type='file'
        multiple
        hidden
      />

      <KargoActionLoadingButton
        data-testid='upload-button'
        loading={isUploading}
        disabled={isUploading}
        onClick={() => {
          fileUploadRef.current?.click();
        }}
      >
        {allowedFileLimit > 1 ? pluralize(buttonText) : buttonText}
      </KargoActionLoadingButton>

      <>
        {uploadError && <p>{uploadErrorMessage}</p>}
        {!uploadError && errors[FILE_FIELD_NAME] && (
          <p>{errors[FILE_FIELD_NAME]?.message}</p>
        )}
      </>
    </Stack>
  );
};

export { KargoSimpleUpload };
