import { PermissionsContext } from '@kargo/context/permissions';
import { useContext } from 'react';

export const usePermissions = () => {
  const { permissions } = useContext(PermissionsContext);

  if (!permissions) {
    throw new Error(
      'Permissions must be initialized to use PermissionsContext',
    );
  }

  return { permissions };
};
