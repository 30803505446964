import Icon from '@components/kargo-ui/icon';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import type { NavLinkItemProps } from '../nav-item/nav-item';

const EXPANDABLE_NAV_ITEM_LEFT_PADDING = 24;

const StyledNavAccordion = styled(Accordion)`
  color: ${(p) => p.theme.colors.sidebarUnselectedText};
  background-color: ${(p) => p.theme.colors.sidebarUnselectedBackground};
  box-shadow: none;

  :before {
    height: 0;
    display: none;
  }
`;

type StyledNavAccordionSummaryProps = {
  isSelected: boolean;
};

const StyledNavAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<StyledNavAccordionSummaryProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 0;
  font-size: 0.875rem;
  transition: all 0.4s ease-out;
  padding: 0;

  ${(p) =>
    p.isSelected
      ? css`
          color: ${p.theme.colors.sidebarSelectedText};
          background-color: ${p.theme.colors.sidebarSelectedBackground};
        `
      : css`
          color: ${p.theme.colors.sidebarUnselectedText};
          background-color: ${p.theme.colors.sidebarUnselectedBackground};
        `}

  :hover {
    color: ${(p) => p.theme.colors.white};
  }

  & .expandable-nav-item__accordion-summary-content {
    margin: 0;
  }
`;

type StyledNavSummaryAnchorProps = {
  nestLevel: number;
};

const StyledNavSummaryAnchor = styled.a<StyledNavSummaryAnchorProps>`
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: ${(p) =>
    `12px 4px 12px ${EXPANDABLE_NAV_ITEM_LEFT_PADDING + p.nestLevel * 16}px`};

  :focus-visible {
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

type StyledNavLabelProps = {
  isHighlighted: boolean;
};

const StyledNavLabel = styled.p<StyledNavLabelProps>`
  display: flex;
  margin-left: 14px;
  font-weight: ${(p) => (p.isHighlighted ? 600 : 400)};
`;

const StyledNavDetailsAnchor = styled.a`
  :focus-visible {
    outline: 1px auto ${(p) => p.theme.colors.white};
  }
`;

type StyledNavAccordionDetailsProps = {
  isSelected: boolean;
  nestLevel: number;
};

const StyledNavAccordionDetails = styled(AccordionDetails, {
  shouldForwardProp: (prop) => !['isSelected', 'nestLevel'].includes(prop),
})<StyledNavAccordionDetailsProps>`
  padding: ${(p) =>
    `8px 4px 8px ${
      EXPANDABLE_NAV_ITEM_LEFT_PADDING + 30 + p.nestLevel * 16
    }px`};
  font-size: 0.875rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.4s ease-out;

  ${(p) =>
    p.isSelected
      ? css`
          color: ${p.theme.colors.sidebarSelectedText};
          background-color: ${p.theme.colors.sidebarSelectedBackground};
        `
      : css`
          color: ${p.theme.colors.sidebarUnselectedText};
          background-color: ${p.theme.colors.sidebarUnselectedBackground};
        `}

  :hover {
    color: ${(p) => p.theme.colors.white};
  }
`;

type ExpandableNavLinkItemProps = NavLinkItemProps & {
  subNavItems?: ExpandableNavLinkItemProps[];
};

type Props = {
  expandableNavLinkItem: ExpandableNavLinkItemProps;
  nestLevel?: number;
};

const ExpandableNavItem = ({
  expandableNavLinkItem,
  nestLevel = 0,
}: Props): JSX.Element => {
  const { route } = useRouter();
  const { colors } = useTheme();
  const {
    label,
    href,
    iconComponent,
    subNavItems,
    isDisabled = false,
  } = expandableNavLinkItem;
  const [isExpanded, setIsExpanded] = useState<boolean>(href === route);

  const isSelected = href === route;
  const mainParentPath = route.split('/').slice(0, 2).join('/');
  const isHighlighted = !!mainParentPath && mainParentPath === href;

  useEffect(() => {
    setIsExpanded(isSelected || isHighlighted);
  }, [isSelected, isHighlighted]);

  return (
    <StyledNavAccordion
      expanded={isExpanded}
      elevation={0}
      disableGutters
      square
      onClick={() => {
        setIsExpanded(true);
      }}
    >
      <StyledNavAccordionSummary
        isSelected={isSelected}
        tabIndex={-1}
        classes={{
          content: 'expandable-nav-item__accordion-summary-content',
        }}
      >
        {!isDisabled && (
          <Link href={href} passHref>
            <StyledNavSummaryAnchor nestLevel={nestLevel}>
              {iconComponent && (
                <Icon
                  color={
                    isSelected || isHighlighted ? colors.white : colors.gray500
                  }
                  strokeWidth={isSelected || isHighlighted ? 2 : 1.5}
                  icon={iconComponent}
                />
              )}

              <StyledNavLabel isHighlighted={isHighlighted}>
                {label}
              </StyledNavLabel>
            </StyledNavSummaryAnchor>
          </Link>
        )}

        {isDisabled && (
          <StyledNavSummaryAnchor nestLevel={nestLevel}>
            {iconComponent && (
              <Icon
                color={
                  isSelected || isHighlighted ? colors.white : colors.gray500
                }
                strokeWidth={isSelected || isHighlighted ? 2 : 1.5}
                icon={iconComponent}
              />
            )}

            <StyledNavLabel isHighlighted={isHighlighted}>
              {label}
            </StyledNavLabel>
          </StyledNavSummaryAnchor>
        )}
      </StyledNavAccordionSummary>

      {subNavItems?.map((subNavItem) => {
        const {
          href,
          label,
          subNavItems,
          isVisible = true,
          isDisabled = false,
        } = subNavItem;

        if (!isVisible) {
          return <Fragment key={href} />;
        }

        if (subNavItems?.length) {
          return (
            <ExpandableNavItem
              key={href}
              expandableNavLinkItem={subNavItem}
              nestLevel={nestLevel + 1}
            />
          );
        }

        return (
          <Fragment key={href}>
            {!isDisabled && (
              <Link href={href} passHref>
                <StyledNavDetailsAnchor>
                  <StyledNavAccordionDetails
                    isSelected={href === route}
                    nestLevel={nestLevel}
                  >
                    {label}
                  </StyledNavAccordionDetails>
                </StyledNavDetailsAnchor>
              </Link>
            )}

            {isDisabled && (
              <StyledNavDetailsAnchor>
                <StyledNavAccordionDetails
                  isSelected={href === route}
                  nestLevel={nestLevel}
                >
                  {label}
                </StyledNavAccordionDetails>
              </StyledNavDetailsAnchor>
            )}
          </Fragment>
        );
      })}
    </StyledNavAccordion>
  );
};

export { ExpandableNavItem };
