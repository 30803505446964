import { gql, useMutation } from '@apollo/client';
import { KargoActionButton } from '@components/kargo-ui/action-button';
import { KargoActionLoadingButton } from '@components/kargo-ui/action-loading-button';
import { KargoSelect } from '@components/kargo-ui/select';
import { KargoOption } from '@components/kargo-ui/select/option';
import { KargoTextField } from '@components/kargo-ui/text-field';
import { KargoTooltip } from '@components/kargo-ui/tooltip';
import styled from '@emotion/styled';
import { useFacility } from 'hooks/kargo-ui/use-facility';
import { ErrorOutline } from '@mui/icons-material';
import FormHelperText from '@mui/material/FormHelperText';
import type {
  DashboardActionsSetNotificationMutation,
  DashboardActionsSetNotificationMutationVariables,
  SubscribeToEventInput,
} from 'generated/graphql';
import { SubscribableEvent } from 'generated/graphql';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

const DASHBOARD_ACTIONS_SET_NOTIFICATION_MUTATION = gql`
  mutation DashboardActionsSetNotificationMutation(
    $input: SubscribeToEventInput!
  ) {
    dashboard {
      subscribeToEvent(input: $input) {
        success
        message
      }
    }
  }
`;

const StyledNotificationActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
`;

const StyledNotificationActionDescriptionText = styled.p`
  font-size: 0.875rem;
  padding: 12px;
  margin-bottom: 12px;
  text-align: center;
`;

const StyledNotificationEventForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledNotificationSelectEventContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledNotificationSelectEvent = styled(KargoSelect)`
  padding: 6px 12px;
`;

const StyledNotificationSelectEventDefault = styled.p`
  font-size: 1rem;
  color: ${(p) => p.theme.colors.gray400};
`;

const StyledNotificationSelectEventErrorHelper = styled(FormHelperText)`
  position: absolute;
  top: 40px;
  margin-left: 12px;
  color: ${(p) => p.theme.colors.red800};
`;

const StyledNotificationsActionContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  gap: 12px;
`;

const StyledNotificationActionCancelButton = styled(KargoActionButton)`
  color: ${(p) => p.theme.colors.gray500};
  border-color: ${(p) => p.theme.colors.gray500};
`;

const StyledNotificationActionErrorIcon = styled(ErrorOutline)`
  color: ${(p) => p.theme.colors.red800};

  :focus-visible {
    outline: 2px solid black;
  }
`;

const SubscribableEventMap: Record<SubscribableEvent, string> = {
  [SubscribableEvent.SHIPMENT_ARRIVAL]: 'Shipment Arrival',
  [SubscribableEvent.SHIPMENT_DEPARTURE]: 'Shipment Departure',
};

type DashboardActionsNotificationFormFields = {
  shipmentNumber: string;
  event: SubscribableEvent | '';
};

type Props = {
  onSetNotification: () => void;
  onCancelNotificationAction: () => void;
};

const DashboardActionsNotification = ({
  onSetNotification,
  onCancelNotificationAction,
}: Props): JSX.Element => {
  const { facility } = useFacility();

  const [
    setNotification,
    {
      loading: setNotificationMutationLoading,
      error: setNotificationMutationError,
    },
  ] = useMutation<
    DashboardActionsSetNotificationMutation,
    DashboardActionsSetNotificationMutationVariables
  >(DASHBOARD_ACTIONS_SET_NOTIFICATION_MUTATION);

  const { control, handleSubmit } =
    useForm<DashboardActionsNotificationFormFields>({
      defaultValues: {
        shipmentNumber: '',
        event: '',
      },
    });

  const handleSetNotification = useCallback(
    async ({
      shipmentNumber,
      event,
    }: DashboardActionsNotificationFormFields) => {
      if (!shipmentNumber || !event) {
        return;
      }

      const input: SubscribeToEventInput = {
        businessId: facility.businessId,
        facilityId: facility.id,
        eventId: shipmentNumber,
        eventType: event,
      };

      try {
        await setNotification({
          variables: {
            input,
          },
        });

        onSetNotification();
      } catch (err) {
        console.error(err);
      }
    },
    [facility, onSetNotification, setNotification],
  );

  return (
    <StyledNotificationActionContainer>
      <StyledNotificationActionDescriptionText>
        Receive a notification when a shipment arrives or departs{' '}
        {facility.name}
      </StyledNotificationActionDescriptionText>

      <StyledNotificationEventForm
        onSubmit={handleSubmit(handleSetNotification)}
      >
        <Controller
          control={control}
          name='shipmentNumber'
          rules={{
            required: {
              value: true,
              message: 'Shipment number is required',
            },
          }}
          render={({ field, fieldState }) => (
            <KargoTextField
              variant='outlined'
              fullWidth
              placeholder='Enter shipment number'
              onChange={(e) => {
                const shipmentNumber = e.target.value as string;

                field.onChange(shipmentNumber ?? '');
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='event'
          rules={{
            required: {
              value: true,
              message: 'Event is required',
            },
          }}
          render={({ field, fieldState }) => (
            <StyledNotificationSelectEventContainer>
              <StyledNotificationSelectEvent
                disableUnderline
                displayEmpty
                renderValue={(value) => {
                  if (!value) {
                    return (
                      <StyledNotificationSelectEventDefault>
                        Select event type
                      </StyledNotificationSelectEventDefault>
                    );
                  }

                  return SubscribableEventMap[value as SubscribableEvent];
                }}
                value={field.value}
                error={!!fieldState.error}
                onChange={(e) => {
                  const selectedEvent = e.target.value as SubscribableEvent;

                  if (selectedEvent) {
                    field.onChange(selectedEvent);
                  }
                }}
              >
                {(
                  Object.keys(
                    SubscribableEvent,
                  ) as (keyof typeof SubscribableEvent)[]
                ).map((event) => (
                  <KargoOption key={event} value={event}>
                    {SubscribableEventMap[event]}
                  </KargoOption>
                ))}
              </StyledNotificationSelectEvent>

              {fieldState.error?.message && (
                <StyledNotificationSelectEventErrorHelper color='warning'>
                  {fieldState.error.message}
                </StyledNotificationSelectEventErrorHelper>
              )}
            </StyledNotificationSelectEventContainer>
          )}
        />

        <StyledNotificationsActionContainer>
          <StyledNotificationActionCancelButton
            size='small'
            onClick={onCancelNotificationAction}
          >
            Cancel
          </StyledNotificationActionCancelButton>

          <KargoActionLoadingButton
            type='submit'
            loading={setNotificationMutationLoading}
            size='small'
          >
            Save
          </KargoActionLoadingButton>

          {setNotificationMutationError && (
            <KargoTooltip title='Error setting tracking notification. Please try again.'>
              <StyledNotificationActionErrorIcon tabIndex={0} />
            </KargoTooltip>
          )}
        </StyledNotificationsActionContainer>
      </StyledNotificationEventForm>
    </StyledNotificationActionContainer>
  );
};

export { DashboardActionsNotification };
