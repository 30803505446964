export enum PERMISSIONS_MAP {
  // Customer Permissions

  // Admin Permissions
  MANAGER_BUSINESS_DATA = 'ops:manager_business_data',
  LABELER_ADMIN = 'ops:write:ops_task_assignment',
  LABELER = 'ops:write:ops_task',

  // General Permissions
  INTERNAL_USER = 'internal',
}
