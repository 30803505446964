import { gql } from '@apollo/client';
import { NOTIFICATION_ITEM_CONTENT_CONTAINER_FRAGMENT } from '../shared/item/content-container/fragment';
import { GENERATE_NOTE_CONTENT_NODE_FRAGMENT } from '@components/notes/utils/generate-note-content-node/fragment';

export const NOTIFICATION_ITEM_NOTE_TAG_FRAGMENT = gql`
  fragment NotificationItemNoteTagFragment on NoteTagNotification {
    id
    facility {
      id
    }
    taggingUser {
      id
      firstName
      lastName
    }
    noteLink {
      id

      ... on ShipmentNoteLink {
        id
        note {
          id
          content {
            ...GenerateNoteContentNodeFragment
          }
        }
        noteShipmentLink {
          id
          customerShipmentIdentifier
          ...NotificationItemContentContainerFragment
        }
      }

      ... on ExceptionNoteLink {
        id
        note {
          id
          content {
            ...GenerateNoteContentNodeFragment
          }
        }
        noteExceptionLink {
          id
          link {
            ... on ShipmentExceptionLink {
              id
              shipment {
                id
                ...NotificationItemContentContainerFragment
              }
            }

            ... on ShippingItemExceptionLink {
              item {
                shipment {
                  id
                  ...NotificationItemContentContainerFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${GENERATE_NOTE_CONTENT_NODE_FRAGMENT}
  ${NOTIFICATION_ITEM_CONTENT_CONTAINER_FRAGMENT}
`;
