// Format Markers
export const NOTE_FORMATTER_USER_START_MARKER = '%*{';
export const NOTE_FORMATTER_USER_END_MARKER = '}*%';

export const NOTE_FORMATTER_PALLET_START_MARKER = '*%[';
export const NOTE_FORMATTER_PALLET_END_MARKER = ']%*';

// Node Markers
export const USER_NODE_START_MARKER = '{%';
export const USER_NODE_END_MARKER = '}';

export const PALLET_NODE_START_MARKER = '*%[';
export const PALLET_NODE_END_MARKER = ']%*';
