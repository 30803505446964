import { gql } from '@apollo/client';
import { GET_SHIPMENT_COLORS_FRAGMENT } from '@utils/get-shipment-colors/fragment';

export const NOTIFICATION_ITEM_CONTENT_CONTAINER_FRAGMENT = gql`
  fragment NotificationItemContentContainerFragment on TruckShipment {
    id
    ...GetShipmentColorsFragment
  }

  ${GET_SHIPMENT_COLORS_FRAGMENT}
`;
