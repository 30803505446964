import styled from '@emotion/styled';
import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';

const KargoActionButton = styled((props: ButtonProps) => (
  <Button
    variant='outlined'
    {...props}
    classes={{
      disabled: 'kargo-action-button--disabled',
      ...props.classes,
    }}
  />
))`
  padding-left: 20px;
  padding-right: 20px;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: none;
  border: 2px solid ${(p) => p.theme.colors.gray900};
  border-radius: 30px;

  :hover {
    color: ${(p) => p.theme.colors.white};
    background: none;
    background-color: ${(p) => p.theme.colors.black};
    border: 2px solid ${(p) => p.theme.colors.gray900};
  }

  :focus-visible {
    outline: 2px solid;
  }

  &.kargo-action-button--disabled {
    border-width: 2px;
  }
`;

export { KargoActionButton };
