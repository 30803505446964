import { AdminLinkButton } from '@layouts/dashboard/admin-link-button';

const DashboardAdminLinkButton = () => {
  const setCurrentDashboardUrlInLocalStorage = () => {
    window.localStorage.setItem(
      'latest_dashboard_page_url',
      JSON.stringify(window.location.href),
    );
  };

  return (
    <AdminLinkButton
      setCurrentDashboardUrlInLocalStorage={
        setCurrentDashboardUrlInLocalStorage
      }
    />
  );
};

export { DashboardAdminLinkButton };
