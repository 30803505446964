import { KargoModal } from '@components/kargo-ui/modal/kargo-modal';
import styled from '@emotion/styled';
import { Add } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from 'next/link';
import { useState } from 'react';
import { useGate } from 'statsig-react';
import { DashboardActionsNotification } from './notification';

const StyledAddIconButtonContainer = styled(IconButton)`
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.internationalOrange700};
  transition: opacity 0.1s ease-in;

  :hover,
  :focus-visible {
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.internationalOrange700};
    opacity: 0.6;
  }
`;

const StyledCreateActionsMenu = styled(Menu)`
  margin-top: 8px;

  & .create-actions-menu-list {
    padding: 0;
  }
`;

const StyledActionsMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const StyledActionText = styled.p`
  font-size: 0.875rem;
  margin: 0;
`;

const StyledActionLink = styled.a`
  font-size: 0.875rem;
`;

const DashboardActionsMenu = (): JSX.Element => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContentType, setModalContentType] = useState<
    'subscriptionModal' | null
  >(null);
  const [modalTitle, setModalTitle] = useState<string>('');

  const { value: isScheduleShipmentEnabled } = useGate(
    'show_shipment_check_in',
  );
  const { value: isTrackingNotificationEnabled } = useGate(
    'show_add_tracking_notification',
  );

  const showAddIcon = [
    isScheduleShipmentEnabled,
    isTrackingNotificationEnabled,
  ].some((flag) => flag);

  return (
    <>
      {showAddIcon && (
        <StyledAddIconButtonContainer
          size='small'
          onClick={(e) => {
            setMenuAnchorEl(e.currentTarget);
          }}
        >
          <Add />
        </StyledAddIconButtonContainer>
      )}

      <StyledCreateActionsMenu
        open={!!menuAnchorEl}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={() => {
          setMenuAnchorEl(null);
        }}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        classes={{
          list: 'create-actions-menu-list',
        }}
      >
        {isScheduleShipmentEnabled && (
          <Link href={'/shipments/new'} passHref>
            <StyledActionsMenuItem>
              <StyledActionLink>Schedule shipment</StyledActionLink>
            </StyledActionsMenuItem>
          </Link>
        )}

        {isTrackingNotificationEnabled && (
          <StyledActionsMenuItem
            onClick={() => {
              setIsModalOpen(true);
              setModalContentType('subscriptionModal');
              setModalTitle('Set tracking notification');
            }}
          >
            <StyledActionText>Set tracking notification</StyledActionText>
          </StyledActionsMenuItem>
        )}
      </StyledCreateActionsMenu>

      <KargoModal
        modalTitle={modalTitle}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        {modalContentType === 'subscriptionModal' && (
          <DashboardActionsNotification
            onSetNotification={() => {
              setIsModalOpen(false);
            }}
            onCancelNotificationAction={() => {
              setIsModalOpen(false);
            }}
          />
        )}
      </KargoModal>
    </>
  );
};

export { DashboardActionsMenu };
