import { gql } from '@apollo/client';
import { NOTIFICATION_ITEM_EXCEPTION_OWNER_FRAGMENT } from '@components/shared/notifications/items/exception-owner/fragment';
import { NOTIFICATION_ITEM_EXCEPTION_SHARED_FRAGMENT } from '@components/shared/notifications/items/exception-shared/fragment';
import { NOTIFICATION_ITEM_NOTE_REPLY_FRAGMENT } from '@components/shared/notifications/items/note-reply/fragment';
import { NOTIFICATION_ITEM_NOTE_TAG_FRAGMENT } from '@components/shared/notifications/items/note-tag/fragment';

export const DASHBOARD_NOTIFICATIONS_LIST_FRAGMENT = gql`
  fragment DashboardNotificationsListFragment on Notification {
    id
    occurredAt
    read

    ... on ExceptionOwnerNotification {
      ...NotificationItemExceptionOwnerFragment
    }

    ... on ExceptionSharedNotification {
      ...NotificationItemExceptionSharedFragment
    }

    ... on NoteTagNotification {
      ...NotificationItemNoteTagFragment
    }

    ... on NoteReplyNotification {
      ...NotificationItemNoteReplyFragment
    }
  }
  ${NOTIFICATION_ITEM_EXCEPTION_OWNER_FRAGMENT}
  ${NOTIFICATION_ITEM_EXCEPTION_SHARED_FRAGMENT}
  ${NOTIFICATION_ITEM_NOTE_TAG_FRAGMENT}
  ${NOTIFICATION_ITEM_NOTE_REPLY_FRAGMENT}
`;
