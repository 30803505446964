import styled from '@emotion/styled';
import { PhotoCameraOutlined } from '@mui/icons-material';
import Link from 'next/link';

const StyledNotePalletAnchor = styled.a`
  display: inline-block;
  padding: 2px 8px;
  margin-right: 6px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(p) => p.theme.colors.shippingBlue};
  background-color: ${(p) => p.theme.colors.white};
  transition: background-color 0.2s ease-in-out;

  :hover {
    background-color: ${(p) => p.theme.colors.gray100};
  }
`;

const StyledNotePalletCameraIcon = styled(PhotoCameraOutlined)`
  color: ${(p) => p.theme.colors.gray500};
  font-size: 14px;
  margin-right: 4px;
`;

export function generateNotePalletNode(
  noteId: string,
  text: string,
): JSX.Element {
  const [url, palletId] = text.split('|');

  return (
    <Link href={url} key={`${noteId}-${palletId}`} passHref>
      <StyledNotePalletAnchor>
        <StyledNotePalletCameraIcon />
        <span>Pallet-{palletId}</span>
      </StyledNotePalletAnchor>
    </Link>
  );
}
