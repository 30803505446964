import styled from '@emotion/styled';

const StyledNoteContentUserLabel = styled.span`
  font-weight: 500;
  text-decoration: underline;
`;

export type UserTagMapType = Record<number, string>;

export function generateNoteUserNode(
  noteId: string,
  text: string,
  userTagMap: UserTagMapType,
): JSX.Element | null {
  const userIdx = Number(text);

  // Only add username node if valid; otherwise, the characters
  // resembling user-replacement markers could have been intended by note owner
  if (!isNaN(userIdx) && userTagMap[userIdx]) {
    return (
      <StyledNoteContentUserLabel key={`${noteId}-${userIdx}`}>
        @{userTagMap[userIdx]}
      </StyledNoteContentUserLabel>
    );
  }

  return null;
}
