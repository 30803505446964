import { gql, useQuery } from '@apollo/client';
import type { OAuthError } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { KargoFullPageLoadingSkeleton } from '@components/kargo-ui/full-page-loading-skeleton';
import { KargoLogo } from '@components/kargo-ui/icons/logo';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import type {
  AuthenticatedUserContextCheckAuthQuery,
  AuthenticatedUserContextCheckAuthQueryVariables,
  MeFragment,
  MeQuery,
  MeQueryVariables,
} from 'generated/graphql';
import { AuthStatus } from 'generated/graphql';
import { createContext, useCallback, useContext, useEffect } from 'react';
import { Auth0TokenContext } from '../auth0-token';
import { StatsigClientContext } from '../statsig-client';
import { useLogger } from '../logging';

const StyledAuthFullContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  height: 100vh;
  padding: 20px;
`;

const StyledAuthErrorText = styled.p`
  font-size: 1.5rem;
`;

const StyledKargoLogo = styled(KargoLogo)`
  font-size: 80px;
`;

const StyledLogoutButton = styled(Button)`
  font-size: 1rem;
  text-transform: none;
  color: ${(p) => p.theme.colors.gray500};

  :hover {
    background: none;
    color: ${(p) => p.theme.colors.black};
  }
`;

const ME_FRAGMENT = gql`
  fragment MeFragment on Me {
    id
    firstName
    lastName
    email
  }
`;

const AUTHENTICATED_USER_CONTEXT_ME_QUERY = gql`
  # This does not follow typical query-naming convention so that it is easy to find in network tab
  query MeQuery {
    me {
      id
      ...MeFragment
    }

    checkAuth
  }
  ${ME_FRAGMENT}
`;

const AUTHENTICATED_USER_CONTEXT_CHECK_AUTH_QUERY = gql`
  query AuthenticatedUserContextCheckAuthQuery {
    checkAuth
  }
`;

type AuthenticatedUserContextType = {
  me: MeFragment | null;
};

const AuthenticatedUserContext = createContext<AuthenticatedUserContextType>({
  me: null,
});

type Props = {
  children: React.ReactNode;
};

const AuthenticatedUserProvider = ({ children }: Props): JSX.Element => {
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const { setAuth0Token } = useContext(Auth0TokenContext);
  const { setUser } = useContext(StatsigClientContext);
  const logger = useLogger();

  const {
    data: authenticatedUserContextMeQueryData,
    loading: authenticatedUserContextMeQueryLoading,
    // TODO: Account for this when Me resolver does not throw error for unauthenticated
    // error: authenticatedUserContextMeQueryError,
  } = useQuery<MeQuery, MeQueryVariables>(AUTHENTICATED_USER_CONTEXT_ME_QUERY, {
    fetchPolicy: 'network-only',
  });

  const {
    data: authenticatedUserContextCheckAuthQueryData,
    loading: authenticatedUserContextCheckAuthQueryLoading,
    error: authenticatedUserContextAuthCheckAuthQueryError,
  } = useQuery<
    AuthenticatedUserContextCheckAuthQuery,
    AuthenticatedUserContextCheckAuthQueryVariables
  >(AUTHENTICATED_USER_CONTEXT_CHECK_AUTH_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const me = authenticatedUserContextMeQueryData?.me;

    if (!me) {
      return;
    }

    const user = {
      userID: me.id,
      email: me.email,
    };

    setUser(user);
  }, [authenticatedUserContextMeQueryData, setUser]);

  const handleRefreshAuth = useCallback(async () => {
    try {
      const newToken = await getAccessTokenSilently({
        ignoreCache: true,
      });

      setAuth0Token(newToken);
    } catch (err) {
      // This is needed because package auth0-react does not have types exported for errors
      const auth0TokenError = err as OAuthError;

      if (auth0TokenError.error === 'login_required') {
        loginWithRedirect();

        return;
      }

      if (auth0TokenError.error === 'consent_required') {
        loginWithRedirect();

        return;
      }

      throw err;
    }
  }, [getAccessTokenSilently, loginWithRedirect, setAuth0Token]);

  if (authenticatedUserContextAuthCheckAuthQueryError) {
    logger.error(
      'Dashboard: Failed authenticated-user check on frontend',
      authenticatedUserContextAuthCheckAuthQueryError,
    );

    return (
      <StyledAuthFullContainer>
        <StyledAuthErrorText>
          Sorry, something went wrong. Please try again or contact an
          administrator.
        </StyledAuthErrorText>
      </StyledAuthFullContainer>
    );
  }

  const kargoUserData = authenticatedUserContextMeQueryData?.me;
  const userAuthorization =
    authenticatedUserContextCheckAuthQueryData?.checkAuth;

  if (userAuthorization === AuthStatus.REFRESH_TOKEN) {
    handleRefreshAuth();
  }

  if (userAuthorization === AuthStatus.UNAUTHENTICATED) {
    return (
      <StyledAuthFullContainer>
        <StyledKargoLogo />

        <StyledAuthErrorText>
          You do not have access to the system. Please request access from an
          administrator.
        </StyledAuthErrorText>

        <StyledLogoutButton
          variant='text'
          onClick={() => {
            logout();
          }}
        >
          Log Out
        </StyledLogoutButton>
      </StyledAuthFullContainer>
    );
  }

  const isLoading =
    authenticatedUserContextMeQueryLoading ||
    authenticatedUserContextCheckAuthQueryLoading ||
    userAuthorization === AuthStatus.REFRESH_TOKEN;

  return (
    <AuthenticatedUserContext.Provider value={{ me: kargoUserData ?? null }}>
      {isLoading ? <KargoFullPageLoadingSkeleton /> : children}
    </AuthenticatedUserContext.Provider>
  );
};

export { AuthenticatedUserContext, AuthenticatedUserProvider };
