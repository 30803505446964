import { useFacility } from 'hooks/kargo-ui/use-facility';
import styled from '@emotion/styled';
import type { NotificationItemNoteTagFragment } from 'generated/graphql';
import { NotificationItem } from '../shared/item';
import { NotificationItemContentContainer } from '../shared/item/content-container';
import { getNotificationFacilityName } from '../utils/get-notification-facility-name';
import { generateNoteContentNode } from '@components/notes/utils/generate-note-content-node/generate-note-content-node';
import { NotificationItemAvatar } from '../shared/avatar';

const StyledNotificationText = styled.p`
  font-size: 0.875rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`;

const StyledUserText = styled.span`
  font-weight: 600;
`;

const noteTagTypeMap: Record<'ExceptionNoteLink' | 'ShipmentNoteLink', string> =
  {
    ExceptionNoteLink: 'exception',
    ShipmentNoteLink: 'shipment',
  };

type Props = {
  notification: NotificationItemNoteTagFragment;
  timestamp?: React.ReactNode;
  isFullyClickable?: boolean;
  showFacilityLabel?: boolean;
  isCentered?: boolean;
};

const NotificationItemNoteTag = ({
  notification,
  timestamp,
  isFullyClickable = false,
  showFacilityLabel = false,
  isCentered = false,
}: Props): JSX.Element => {
  const { facility, taggingUser, noteLink } = notification;
  const { availableFacilities } = useFacility();

  const formattedUser = {
    id: taggingUser.id,
    name: `${taggingUser.firstName} ${taggingUser.lastName}`,
    given_name: taggingUser.firstName,
    family_name: taggingUser.lastName,
  };

  let shipment = null;
  let noteTagId = null;
  let noteTagLink = null;

  if (noteLink.__typename === 'ShipmentNoteLink') {
    shipment = noteLink.noteShipmentLink;
    noteTagId = shipment.customerShipmentIdentifier ?? shipment.id;
    noteTagLink = `/shipments/${shipment.id}`;
  } else if (noteLink.__typename === 'ExceptionNoteLink') {
    noteTagId = noteLink.noteExceptionLink.id;
    noteTagLink = `/exceptions/${noteLink.noteExceptionLink.id}`;

    if (
      noteLink.noteExceptionLink.link?.__typename === 'ShipmentExceptionLink'
    ) {
      shipment = noteLink.noteExceptionLink.link.shipment;
    } else if (
      noteLink.noteExceptionLink.link?.__typename ===
      'ShippingItemExceptionLink'
    ) {
      shipment = noteLink.noteExceptionLink.link.item.shipment;
    }
  }

  const noteTagTypeText =
    noteTagTypeMap[noteLink.__typename ?? 'ShipmentNoteLink'];
  const facilityLabel =
    showFacilityLabel && availableFacilities.length > 1
      ? getNotificationFacilityName(facility.id, availableFacilities)
      : null;

  const noteContentNode = generateNoteContentNode(
    noteLink.note.id,
    noteLink.note.content,
  );

  return (
    <NotificationItem
      startIcon={<NotificationItemAvatar user={formattedUser} />}
      timestamp={timestamp}
      href={isFullyClickable ? noteTagLink : null}
      facilityLabel={facilityLabel}
      isCentered={isCentered}
    >
      <StyledNotificationText>
        <StyledUserText>{formattedUser.name}</StyledUserText> mentioned you in a
        note on {noteTagTypeText}{' '}
        <NotificationItemContentContainer
          href={!isFullyClickable ? noteTagLink : null}
          shipment={shipment}
        >
          {noteTagId}
        </NotificationItemContentContainer>
        : {noteContentNode}
      </StyledNotificationText>
    </NotificationItem>
  );
};

export { NotificationItemNoteTag };
