import styled from '@emotion/styled';
import { NotificationItemContainer } from '../item-container';

const StyledNotificationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const StyledNotificationStartIconContainer = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
`;

const StyledNotificationStampContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledNotificationFacilityLabel = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${(p) => p.theme.colors.gray400};
`;

type Props = {
  startIcon?: React.ReactNode;
  timestamp: React.ReactNode;
  href: string | null;
  facilityLabel?: string | null;
  isCentered?: boolean;
  children: React.ReactNode;
};

const NotificationItem = ({
  startIcon,
  timestamp,
  href,
  facilityLabel,
  isCentered = false,
  children,
}: Props): JSX.Element => {
  return (
    <NotificationItemContainer href={href} isCentered={isCentered}>
      <StyledNotificationStartIconContainer>
        {startIcon}
      </StyledNotificationStartIconContainer>

      <StyledNotificationTextContainer>
        {children}

        <StyledNotificationStampContainer>
          {timestamp}
          {facilityLabel && (
            <StyledNotificationFacilityLabel>
              ({facilityLabel})
            </StyledNotificationFacilityLabel>
          )}
        </StyledNotificationStampContainer>
      </StyledNotificationTextContainer>
    </NotificationItemContainer>
  );
};

export { NotificationItem };
