import { gql } from '@apollo/client';
import { NOTIFICATION_ITEM_CONTENT_CONTAINER_FRAGMENT } from '../shared/item/content-container/fragment';

const NOTIFICATION_ITEM_EXCEPTION_OWNER_SHIPMENT_FRAGMENT = gql`
  fragment NotificationItemExceptionOwnerShipmentFragment on TruckShipment {
    id
    customerShipmentIdentifier
    dock {
      id
      displayName
    }
    ...NotificationItemContentContainerFragment
  }
  ${NOTIFICATION_ITEM_CONTENT_CONTAINER_FRAGMENT}
`;

export const NOTIFICATION_ITEM_EXCEPTION_OWNER_FRAGMENT = gql`
  fragment NotificationItemExceptionOwnerFragment on ExceptionOwnerNotification {
    id
    sharingUser {
      id
      firstName
      lastName
    }
    facility {
      id
    }
    exception {
      id
      title
      link {
        ... on ShipmentExceptionLink {
          id
          shipment {
            id
            ...NotificationItemExceptionOwnerShipmentFragment
          }
        }

        ... on ShippingItemExceptionLink {
          item {
            shipment {
              id
              ...NotificationItemExceptionOwnerShipmentFragment
            }
          }
        }
      }
    }
  }
  ${NOTIFICATION_ITEM_EXCEPTION_OWNER_SHIPMENT_FRAGMENT}
`;
