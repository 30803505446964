import { KargoSelect } from '@components/kargo-ui/select';
import { KargoOption } from '@components/kargo-ui/select/option';
import styled from '@emotion/styled';
import { useDemoMode } from '@hooks/kargo-ui/use-demo-mode';
import { ExpandMore } from '@mui/icons-material';
import type { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import type { SelectChangeEvent } from '@mui/material/Select';
import { useFacility } from 'hooks/kargo-ui/use-facility';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

const StyledHeaderNavigationDemoModeText = styled.p`
  font-size: 0.875rem;
  color: ${(p) => p.theme.colors.gray500};
  font-style: italic;
`;

const StyledDashboardBreadcrumbs = styled((props: BreadcrumbsProps) => (
  <Breadcrumbs
    {...props}
    classes={{
      li: 'dashboard-header-navigation__breadcrumb_list-item',
      ...props.classes,
    }}
  />
))`
  & .dashboard-header-navigation__breadcrumb_list-item {
    display: flex;
  }
`;

const StyledFacilitySelectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const StyledKargoOption = styled(KargoOption)`
  color: ${(p) => p.theme.colors.menuFontDisabled};

  &.selected {
    background: inherit;
    color: ${(p) => p.theme.colors.menuFontSelected};
  }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.contentPrimary};
  font-size: 0.875rem;
`;

const StyledNonLinkText = styled.p`
  color: ${({ theme }) => theme.colors.contentPrimary};
  font-size: 0.875rem;
`;

function handleNavigation(router: NextRouter): void {
  // Redirect to proper base pages when changing facilities
  if (
    ['/shipments/new', '/shipments/[shipmentId]'].some((urlPath) =>
      router.pathname.includes(urlPath),
    )
  ) {
    router.push('/shipments');

    return;
  }

  if (router.pathname === '/orders/[orderId]') {
    router.push('/orders');

    return;
  }

  if (
    [
      '/exceptions/[exceptionId]',
      '/exceptions/settings',
      '/exceptions/create',
    ].includes(router.pathname)
  ) {
    router.push('/exceptions');

    return;
  }

  if (
    ['/forklift-sessions', '/forklift-sessions/[forkliftSessionId]'].includes(
      router.pathname,
    )
  ) {
    router.push('/');

    return;
  }
}

export type DashboardBreadcrumb = { name: string | JSX.Element; uri?: string };

type Props = {
  breadcrumbs?: DashboardBreadcrumb[];
};

const DashboardHeaderNavigation = ({ breadcrumbs }: Props): JSX.Element => {
  const router = useRouter();
  const { facility, availableFacilities, setFacility } = useFacility();
  const { isDemoMode } = useDemoMode();

  const handleOnFacilityChange = useCallback(
    async (e: SelectChangeEvent<unknown>) => {
      const selectedFacility = availableFacilities.find(
        (facilityOption) => facilityOption.id === e.target.value,
      );

      if (selectedFacility) {
        setFacility(selectedFacility);
        handleNavigation(router);
      }
    },
    [availableFacilities, setFacility, router],
  );

  return (
    <>
      {isDemoMode && (
        <StyledHeaderNavigationDemoModeText>
          Hidden for demo mode
        </StyledHeaderNavigationDemoModeText>
      )}

      {!isDemoMode && (
        <StyledDashboardBreadcrumbs>
          <StyledFacilitySelectionContainer>
            <KargoSelect
              value={facility.id}
              disableUnderline
              IconComponent={ExpandMore}
              placeholder='Facility'
              onChange={handleOnFacilityChange}
            >
              {availableFacilities.map(({ id, name, businessName }) => {
                const label = `${businessName} - ${name}`;

                return (
                  <StyledKargoOption
                    key={label}
                    value={id}
                    classes={{
                      selected: 'selected',
                    }}
                  >
                    {label}
                  </StyledKargoOption>
                );
              })}
            </KargoSelect>
          </StyledFacilitySelectionContainer>

          {breadcrumbs?.map(({ name, uri }, index, list) =>
            uri && index !== list.length - 1 ? (
              <StyledLink key={index} href={uri} underline='hover'>
                {name}
              </StyledLink>
            ) : (
              <StyledNonLinkText key={index}>{name}</StyledNonLinkText>
            ),
          )}
        </StyledDashboardBreadcrumbs>
      )}
    </>
  );
};

export { DashboardHeaderNavigation };
