import type { GenerateNoteContentNodeFragment } from 'generated/graphql';
import { Fragment } from 'react';
import {
  PALLET_NODE_END_MARKER,
  PALLET_NODE_START_MARKER,
  USER_NODE_END_MARKER,
  USER_NODE_START_MARKER,
} from '../constants';
import { generateNotePalletNode } from './pallet-node/generate-note-pallet-node';
import type { UserTagMapType } from './user-node/generate-note-user-node';
import { generateNoteUserNode } from './user-node/generate-note-user-node';

type NodeMarkerMapType = Record<
  string,
  {
    endMarker: string;
    generateNodeFunction: Function;
  }
>;

const NODE_MARKER_MAP: NodeMarkerMapType = {
  [USER_NODE_START_MARKER]: {
    endMarker: USER_NODE_END_MARKER,
    generateNodeFunction: generateNoteUserNode,
  },
  [PALLET_NODE_START_MARKER]: {
    endMarker: PALLET_NODE_END_MARKER,
    generateNodeFunction: generateNotePalletNode,
  },
};

export function generateNoteContentNode(
  noteId: string,
  noteContent: GenerateNoteContentNodeFragment,
): React.ReactNode {
  const { text, userMentions } = noteContent;

  const noteContentNode: React.ReactNode[] = [];
  const userTagMap: UserTagMapType = (userMentions ?? []).reduce(
    (acc, curr) => {
      const { tags, user } = curr;

      tags.forEach((tag) => (acc[tag] = `${user.firstName} ${user.lastName}`));

      return acc;
    },
    {} as UserTagMapType,
  );

  const startMarkers = Object.keys(NODE_MARKER_MAP);

  let textNodeToAdd = '';
  for (let i = 0; i < text.length; i++) {
    // Check if current index and subsequent letters match any start markers
    const startMarker = startMarkers.find(
      (startMarker) => text.slice(i, i + startMarker.length) === startMarker,
    );

    if (startMarker) {
      const { endMarker, generateNodeFunction } = NODE_MARKER_MAP[startMarker];
      const startFormatterIdx = i + startMarker.length;
      let currentFormatterIdx = startFormatterIdx;
      let endSegment = text.slice(
        currentFormatterIdx,
        currentFormatterIdx + endMarker.length,
      );

      // Loop through following letters and see if matching user-replacement end marker
      while (endSegment !== endMarker && currentFormatterIdx < text.length) {
        currentFormatterIdx++;

        endSegment = text.slice(
          currentFormatterIdx,
          currentFormatterIdx + endMarker.length,
        );
      }

      if (endSegment === endMarker) {
        // Add previously-built text content before adding username replacement
        noteContentNode.push(
          <Fragment key={`${noteId}-${startFormatterIdx}`}>
            {textNodeToAdd}
          </Fragment>,
        );
        textNodeToAdd = '';

        // Pass userTagMap for now, even though some of the generate functions do not need. If this
        // grows, then should refactor and not be generic
        const nodeToAdd = generateNodeFunction(
          noteId,
          text.slice(startFormatterIdx, currentFormatterIdx),
          userTagMap,
        );

        if (nodeToAdd) {
          noteContentNode.push(nodeToAdd);
          i = currentFormatterIdx + endMarker.length - 1;

          continue;
        }
      }
    }

    textNodeToAdd += text[i];
  }

  if (textNodeToAdd) {
    noteContentNode.push(
      <Fragment key={`${noteId}-${text.length}`}>{textNodeToAdd}</Fragment>,
    );
  }

  return noteContentNode;
}
