import { useFacility } from 'hooks/kargo-ui/use-facility';
import styled from '@emotion/styled';
import { Error } from '@mui/icons-material';
import type { NotificationItemExceptionSharedFragment } from 'generated/graphql';
import { NotificationItemAvatar } from '../shared/avatar';
import { NotificationItem } from '../shared/item';
import { NotificationItemContentContainer } from '../shared/item/content-container';
import { getNotificationFacilityName } from '../utils/get-notification-facility-name';

const StyledNotificationText = styled.p`
  font-size: 0.875rem;
`;

const StyledUserText = styled.span`
  font-weight: 600;
`;

const StyledNotificationExceptionIcon = styled(Error)`
  height: 26px;
  width: 26px;
  color: ${(p) => p.theme.colors.warningDanger};
`;

type Props = {
  notification: NotificationItemExceptionSharedFragment;
  timestamp?: React.ReactNode;
  isFullyClickable?: boolean;
  showFacilityLabel?: boolean;
  isCentered?: boolean;
};

const NotificationItemExceptionShared = ({
  notification,
  timestamp,
  isFullyClickable = false,
  showFacilityLabel,
  isCentered = false,
}: Props): JSX.Element => {
  const { facility, exception, sharingUser } = notification;
  const { availableFacilities } = useFacility();

  const formattedUser = sharingUser && {
    id: sharingUser.id,
    name: `${sharingUser.firstName} ${sharingUser.lastName}`,
    given_name: sharingUser.firstName,
    family_name: sharingUser.lastName,
  };

  let shipment = null;

  if (exception.link?.__typename === 'ShipmentExceptionLink') {
    shipment = exception.link.shipment;
  } else if (exception.link?.__typename === 'ShippingItemExceptionLink') {
    shipment = exception.link.item.shipment;
  }

  const exceptionHref = `/exceptions/${exception.id}`;
  const shipmentHref = `/shipments/${shipment?.id}`;
  const facilityLabel =
    showFacilityLabel && availableFacilities.length > 1
      ? getNotificationFacilityName(facility.id, availableFacilities)
      : null;

  return (
    <NotificationItem
      startIcon={
        formattedUser ? (
          <NotificationItemAvatar user={formattedUser} />
        ) : (
          <StyledNotificationExceptionIcon />
        )
      }
      timestamp={timestamp}
      href={isFullyClickable ? exceptionHref : null}
      facilityLabel={facilityLabel}
      isCentered={isCentered}
    >
      {sharingUser && (
        <StyledNotificationText>
          <StyledUserText>
            {sharingUser.firstName} {sharingUser.lastName}
          </StyledUserText>{' '}
          has shared exception{' '}
          <NotificationItemContentContainer
            href={!isFullyClickable ? exceptionHref : null}
            shipment={shipment}
          >
            {exception.id}
          </NotificationItemContentContainer>{' '}
          with you.
        </StyledNotificationText>
      )}

      {!sharingUser && shipment && (
        <StyledNotificationText>
          <NotificationItemContentContainer
            href={!isFullyClickable ? exceptionHref : null}
            shipment={shipment}
          >
            {exception.title}
          </NotificationItemContentContainer>{' '}
          detected for shipment{' '}
          <NotificationItemContentContainer
            href={!isFullyClickable ? shipmentHref : null}
            shipment={shipment}
          >
            {shipment?.customerShipmentIdentifier ?? shipment?.id}
          </NotificationItemContentContainer>{' '}
          at Dock Door {shipment?.dock?.displayName}
        </StyledNotificationText>
      )}

      {!sharingUser && !shipment && (
        <StyledNotificationText>
          Exception{' '}
          <NotificationItemContentContainer
            href={!isFullyClickable ? exceptionHref : null}
            shipment={shipment}
          >
            {exception.id}
          </NotificationItemContentContainer>{' '}
          has been shared with you.
        </StyledNotificationText>
      )}
    </NotificationItem>
  );
};

export { NotificationItemExceptionShared };
