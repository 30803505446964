import type { User } from '@auth0/auth0-react';
import { KargoAvatarBubble } from '@components/kargo-ui/avatar/bubble';
import styled from '@emotion/styled';

const StyledUserAvatarBubble = styled(KargoAvatarBubble)`
  height: 30px;
  width: 30px;
  font-size: 0.875rem;
`;

type Props = {
  user: User | undefined;
};

const NotificationItemAvatar = ({ user }: Props): JSX.Element => {
  return (
    <StyledUserAvatarBubble avatarHash={user?.name}>
      {user && `${user?.given_name?.[0]}${user?.family_name?.[0]}`}
    </StyledUserAvatarBubble>
  );
};

export { NotificationItemAvatar };
