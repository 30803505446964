import styled from '@emotion/styled';
import type { SelectProps } from '@mui/material';
import { FormHelperText, Select } from '@mui/material';

const StyledKargoSelectErrorText = styled(FormHelperText)`
  margin-left: 12px;
`;

type KargoSelectProps = SelectProps & {
  error?: boolean;
  helpertext?: string;
};

const KargoSelect = styled((props: KargoSelectProps) => (
  <>
    <Select
      variant='standard'
      {...props}
      sx={{
        '& .MuiSelect-select': {
          '&:focus': {
            backgroundColor: 'inherit',
          },
        },
        ...props.sx,
      }}
      MenuProps={{
        sx: {
          '& .MuiMenu-list': {
            padding: 0,
          },
        },
        ...props.MenuProps,
      }}
      classes={{ ...props.classes }}
    />

    {props.error && props.helpertext && (
      <StyledKargoSelectErrorText error>
        {props.helpertext}
      </StyledKargoSelectErrorText>
    )}
  </>
))`
  font-size: ${(p) => p.theme.sizing.scale700};
  font-weight: 400;
`;

export { KargoSelect };
