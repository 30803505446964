import { gql } from '@apollo/client';
import { GENERATE_NOTE_USER_NODE_FRAGMENT } from './user-node/fragment';

export const GENERATE_NOTE_CONTENT_NODE_FRAGMENT = gql`
  fragment GenerateNoteContentNodeFragment on NoteContent {
    text
    userMentions {
      tags
      user {
        id
        ...GenerateNoteUserNodeFragment
      }
    }
  }
  ${GENERATE_NOTE_USER_NODE_FRAGMENT}
`;
