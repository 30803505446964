import type { DashboardFacility } from '@kargo/context/facility/facility-context';

const facilityMemoCache: Map<DashboardFacility['id'], string> = new Map();

export function getNotificationFacilityName(
  facilityId: number,
  facilitiesList: DashboardFacility[],
): string | null {
  const facilityCacheName = facilityMemoCache.get(facilityId);

  if (facilityCacheName) {
    return facilityCacheName;
  }

  const facility = facilitiesList.find(({ id }) => id === facilityId);

  if (!facility) {
    return null;
  }

  const facilityName = `${`${facility.businessName} - ${facility.name}`}`;

  facilityMemoCache.set(facilityId, facilityName);

  return facilityName;
}
