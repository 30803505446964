import styled from '@emotion/styled';
import { useDemoMode } from '@hooks/kargo-ui/use-demo-mode';
import Fab from '@mui/material/Fab';

const StyledDashboardDemoFab = styled(Fab)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.warningBackgroundPrimary};
  transition: opacity 0.2s ease-in-out;

  :hover,
  :focus-visible {
    color: ${(p) => p.theme.colors.white};
    background-color: ${(p) => p.theme.colors.warningBackgroundPrimary};
    opacity: 0.7;
  }
`;

const StyledDemoFabText = styled.p`
  font-size: 0.75rem;
  text-transform: capitalize;
`;

const DashboardDemoButton = (): JSX.Element => {
  const { isDemoMode, toggleDemoMode } = useDemoMode();

  const demoStateText = isDemoMode ? 'On' : 'Off';

  return (
    <StyledDashboardDemoFab
      variant='extended'
      size='small'
      onClick={toggleDemoMode}
    >
      <StyledDemoFabText>
        Demo mode (<b>{demoStateText}</b>)
      </StyledDemoFabText>
    </StyledDashboardDemoFab>
  );
};

export { DashboardDemoButton };
