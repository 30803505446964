import { useEffect } from 'react';

/**
 * Hook used to facilitate GraphQL subscription and unsubscription based on whether the tab is active or inactive.
 * Upon a tab becoming inactive, an unsubscription will be made; upon that same tab becoming active again,
 * re-subscription will occur, along with calling any additional function (e.g. a query refetch)
 *
 * @param {Function} subscriptionFn GraphQL subscription function to use when tab is active/inactive
 * @param {Function} [onTabActive] Callback to execute when tab becomes active
 */
const useActiveTabSubscription = (
  subscriptionFn: () => () => void,
  onTabActive: Function = () => {},
) => {
  useEffect(() => {
    let unsubscribeFn: Function | null = null;
    let hasInitialized = false;

    const handleSubscription = () => {
      const visibility = document.visibilityState;

      if (visibility === 'visible') {
        if (hasInitialized) {
          onTabActive();
        }

        if (!unsubscribeFn) {
          unsubscribeFn = subscriptionFn();
        }
      }

      if (visibility === 'hidden') {
        if (unsubscribeFn) {
          unsubscribeFn();
        }

        unsubscribeFn = null;
      }
    };

    window.addEventListener('visibilitychange', handleSubscription);

    // Run initial subscription and set initialization
    handleSubscription();
    hasInitialized = true;

    return () => {
      if (unsubscribeFn) {
        unsubscribeFn();
      }

      window.removeEventListener('visibilitychange', handleSubscription);
    };
  }, [subscriptionFn, onTabActive]);
};

export { useActiveTabSubscription };
