import { css } from '@emotion/react';
import styled from '@emotion/styled';

type StyledNotificationItemContainerProps = {
  isCentered: boolean;
};

const StyledNotificationItemContainer = styled.div<StyledNotificationItemContainerProps>`
  display: flex;
  gap: 12px;

  ${(p) =>
    p.isCentered &&
    css`
      align-items: center;
    `}
`;

type StyledNotificationItemLinkContainerProps = {
  isCentered: boolean;
};

const StyledNotificationItemLinkContainer = styled.a<StyledNotificationItemLinkContainerProps>`
  display: flex;
  gap: 12px;

  ${(p) =>
    p.isCentered &&
    css`
      align-items: center;
    `}
`;

type Props = {
  href: string | null;
  isCentered?: boolean;
  children: React.ReactNode;
};

const NotificationItemContainer = ({
  href,
  isCentered = false,
  children,
}: Props): JSX.Element => {
  return (
    <>
      {href ? (
        <StyledNotificationItemLinkContainer
          href={href}
          isCentered={isCentered}
        >
          {children}
        </StyledNotificationItemLinkContainer>
      ) : (
        <StyledNotificationItemContainer isCentered={isCentered}>
          {children}
        </StyledNotificationItemContainer>
      )}
    </>
  );
};

export { NotificationItemContainer };
