import { gql, useMutation, useQuery } from '@apollo/client';
import { useActiveTabSubscription } from 'hooks/kargo-ui/use-active-tab-subscription';
import styled from '@emotion/styled';
import {
  ErrorOutline,
  NotificationsActive,
  NotificationsNone,
} from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import type { MenuItemProps } from '@mui/material/MenuItem';
import MenuItem from '@mui/material/MenuItem';
import type {
  DashboardNotificationsQuery,
  DashboardNotificationsQueryVariables,
  DashboardNotificationsReadNotificationMutation,
  DashboardNotificationsReadNotificationMutationVariables,
  DashboardNotificationsSubscription,
  DashboardNotificationsSubscriptionVariables,
} from 'generated/graphql';
import { useCallback, useState } from 'react';
import { DashboardNotificationsList } from './list';
import { DASHBOARD_NOTIFICATIONS_LIST_FRAGMENT } from './list/fragment';

const DASHBOARD_NOTIFICATIONS_FRAGMENT = gql`
  fragment DashboardNotificationsFragment on Notification {
    id
    read
    ...DashboardNotificationsListFragment
  }
  ${DASHBOARD_NOTIFICATIONS_LIST_FRAGMENT}
`;

const DASHBOARD_NOTIFICATIONS_QUERY = gql`
  query DashboardNotificationsQuery(
    $facilityId: Int
    $isRead: Boolean
    $types: [NotificationType!]
    $limit: Int!
    $offset: Int!
  ) {
    userNotifications(
      facilityId: $facilityId
      isRead: $isRead
      types: $types
      limit: $limit
      offset: $offset
    ) {
      ...DashboardNotificationsFragment
    }
  }
  ${DASHBOARD_NOTIFICATIONS_FRAGMENT}
`;

const DASHBOARD_NOTIFICATIONS_READ_NOTIFICATION_MUTATION = gql`
  mutation DashboardNotificationsReadNotificationMutation($input: [ID!]!) {
    dashboard {
      markNotificationsRead(input: $input) {
        id
        read
      }
    }
  }
`;

const DASHBOARD_NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription DashboardNotificationsSubscription {
    notification {
      ...DashboardNotificationsFragment
    }
  }
  ${DASHBOARD_NOTIFICATIONS_FRAGMENT}
`;

const StyledNotificationsIconButton = styled(IconButton)`
  background: none;
  padding: 0;
  transition: opacity 0.1s ease-in;

  :hover,
  :focus-visible {
    background: none;
    opacity: 0.6;
  }
`;

const StyledNotificationsUnreadContainer = styled.div`
  position: relative;
`;

const StyledNotificationsUnreadBadge = styled.div`
  position: absolute;
  right: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.internationalOrange700};
  color: ${(p) => p.theme.colors.white};
  font-size: 0.5rem;
  font-weight: 600;
  height: 14px;
  width: 14px;
  border-radius: 3px;
`;

const StyledNotificationsIcon = styled(NotificationsActive)`
  height: 24px;
  width: 24px;
`;

const StyledNotificationsIconNone = styled(NotificationsNone)`
  height: 24px;
  width: 24px;
`;

const StyledNotificationsMenu = styled(Menu)`
  margin-top: 8px;

  & .dashboard-notifications-menu {
    width: 350px;
    padding: 0;
  }
`;

const StyledNoNotificationsText = styled.p`
  font-size: 0.875rem;
  padding: 16px;
  text-align: center;
  font-style: italic;
`;

type StyledSeeMoreNotificationsMenuItemProps = MenuItemProps<
  'a',
  { component?: 'a' }
>;

const StyledSeeMoreNotificationsMenuItem = styled(
  MenuItem,
)<StyledSeeMoreNotificationsMenuItemProps>`
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  padding: 16px;
`;

const StyledDashboardNotificationsErrorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 24px;
  color: ${(p) => p.theme.colors.warningDanger};
`;

const StyledDashboardNotificationErrorIcon = styled(ErrorOutline)`
  font-size: 16px;
  margin-top: 2px;
`;

const StyledDashboardNotificationsErrorText = styled.p`
  font-size: 0.825rem;
`;

const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(p) => p.theme.colors.gray400};
`;

const DashboardNotifications = (): JSX.Element => {
  const {
    data: notificationsData,
    loading: notificationsQueryLoading,
    error: notificationsQueryError,
    subscribeToMore: notificationsQuerySubscribeToMore,
    refetch: notificationsQueryRefetch,
  } = useQuery<
    DashboardNotificationsQuery,
    DashboardNotificationsQueryVariables
  >(DASHBOARD_NOTIFICATIONS_QUERY, {
    variables: {
      limit: 5,
      offset: 0,
      isRead: false,
    },
  });

  const [readNotifications] = useMutation<
    DashboardNotificationsReadNotificationMutation,
    DashboardNotificationsReadNotificationMutationVariables
  >(DASHBOARD_NOTIFICATIONS_READ_NOTIFICATION_MUTATION);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const notificationsSubscription = useCallback(() => {
    return notificationsQuerySubscribeToMore<
      DashboardNotificationsSubscription,
      DashboardNotificationsSubscriptionVariables
    >({
      document: DASHBOARD_NOTIFICATIONS_SUBSCRIPTION,
      updateQuery(
        prevNotificationsQueryData,
        { subscriptionData: notificationsSubscriptionData },
      ) {
        const previousNotifications =
          prevNotificationsQueryData.userNotifications;
        const newNotification = notificationsSubscriptionData.data.notification;

        return Object.assign({
          ...prevNotificationsQueryData,
          notifications: [newNotification, ...previousNotifications],
        });
      },
    });
  }, [notificationsQuerySubscribeToMore]);

  useActiveTabSubscription(
    notificationsSubscription,
    notificationsQueryRefetch,
  );

  const notifications = notificationsData?.userNotifications ?? [];
  const unreadNotifications = notifications.filter(({ read }) => !read);

  const handleNotificationClose = async () => {
    const unreadNotificationIds = unreadNotifications.map(({ id }) => id);

    setAnchorEl(null);

    if (unreadNotificationIds.length) {
      readNotifications({
        variables: {
          input: unreadNotificationIds,
        },
      });
    }
  };

  return (
    <>
      <StyledNotificationsIconButton
        size='small'
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        {unreadNotifications.length === 0 && <StyledNotificationsIconNone />}

        {unreadNotifications.length > 0 && (
          <StyledNotificationsUnreadContainer>
            <StyledNotificationsUnreadBadge>
              {unreadNotifications.length}
            </StyledNotificationsUnreadBadge>
            <StyledNotificationsIcon />
          </StyledNotificationsUnreadContainer>
        )}
      </StyledNotificationsIconButton>

      <StyledNotificationsMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={handleNotificationClose}
        onClose={handleNotificationClose}
        classes={{
          list: 'dashboard-notifications-menu',
        }}
      >
        {notificationsQueryError && (
          <StyledDashboardNotificationsErrorContainer>
            <StyledDashboardNotificationErrorIcon />

            <StyledDashboardNotificationsErrorText>
              Unable to fetch notifications. Please contact an administrator or
              try again later.
            </StyledDashboardNotificationsErrorText>
          </StyledDashboardNotificationsErrorContainer>
        )}

        {notificationsQueryLoading ? (
          <StyledLoadingContainer>
            <StyledCircularProgress size={24} />
          </StyledLoadingContainer>
        ) : (
          <div>
            {notifications.length === 0 ? (
              <StyledNoNotificationsText>
                No unread notifications
              </StyledNoNotificationsText>
            ) : (
              <DashboardNotificationsList notifications={notifications} />
            )}

            <StyledSeeMoreNotificationsMenuItem
              component='a'
              href='/notifications'
            >
              See all notifications
            </StyledSeeMoreNotificationsMenuItem>
          </div>
        )}
      </StyledNotificationsMenu>
    </>
  );
};

export { DashboardNotifications };
