import { gql } from '@apollo/client';
import { NOTIFICATION_ITEM_CONTENT_CONTAINER_FRAGMENT } from '../shared/item/content-container/fragment';

const NOTIFICATION_ITEM_EXCEPTION_SHARED_SHIPMENT_FRAGMENT = gql`
  fragment NotificationItemExceptionSharedShipmentFragment on TruckShipment {
    id
    customerShipmentIdentifier
    dock {
      id
      displayName
    }
    ...NotificationItemContentContainerFragment
  }
  ${NOTIFICATION_ITEM_CONTENT_CONTAINER_FRAGMENT}
`;

export const NOTIFICATION_ITEM_EXCEPTION_SHARED_FRAGMENT = gql`
  fragment NotificationItemExceptionSharedFragment on ExceptionSharedNotification {
    id
    sharingUser {
      id
      firstName
      lastName
    }
    facility {
      id
    }
    exception {
      id
      title
      link {
        ... on ShipmentExceptionLink {
          id
          shipment {
            id
            ...NotificationItemExceptionSharedShipmentFragment
          }
        }

        ... on ShippingItemExceptionLink {
          item {
            shipment {
              id
              ...NotificationItemExceptionSharedShipmentFragment
            }
          }
        }
      }
    }
  }
  ${NOTIFICATION_ITEM_EXCEPTION_SHARED_SHIPMENT_FRAGMENT}
`;
